.title {
  font-size: 175%;
  font-family: 'Shrikhand', cursive;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #e2ec4e;
  padding: 20px 0px;
  margin: auto;
  max-width: max-content;
}

.ag-header-cell-label {
  justify-content: center;
  font-size: 20px;
}

.AgGridReact {
  --ag-background-color: #128b22;
  --ag-odd-row-background-color: #128b22;
  --ag-header-background-color: #128b22;
  --ag-header-foreground-color: #e2ec4e;
  --ag-border-color: #e2ec4e;
  --ag-secondary-border-color: #e2ec4e;
  font-family: 'Amaranth', sans-serif !important;
  text-align: center;
  width: 100%;
  height: 500px;
}

button {
  background-color: #128b22;
  border: 3px solid #e2ec4e;
  color: #e2ec4e;
  padding: 15px 109px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-family: 'Amaranth', sans-serif;
}

.windowText {
  font-family: 'Amaranth', sans-serif;
  color: #e2ec4e;
  padding: 5px 5px;
  text-align: center; 
}

input {
  padding: 2.5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #e2ec4e;
}